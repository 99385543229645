import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../../core/services/parking.service';
import { ParkingEventLogService } from '../../core/services/parking-event-log.service';
import { LocalDataSource } from 'ng2-smart-table';
import { formatDate, DecimalPipe } from '@angular/common';
import { UtilsService } from 'src/app/core/utils/utils.service';
@Component({
  selector: 'app-by-date-report',
  templateUrl: './by-date-report.component.html',
  styleUrls: ['./by-date-report.component.scss']
})
export class ByDateReportComponent implements OnInit {
  dateFrom;
  dateTo;
  parking;
  totalValue = 0;
  items: LocalDataSource;

  tableSettings = {
    attr: {
      class: 'table table-striped mb-0'
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    noDataMessage: 'No hay registros',
    pager: {
      display: false,
    },
    columns: {
      okDepartureDate: {
        title: 'Fecha Salida'
      },
      entryHour: {
        title: 'Hora de Ingreso'
      },
      departureHour: {
        title: 'Hora de Salida'
      },
      timeInParking: {
        title: 'Tiempo de Parqueo'
      },
      licensePlate: {
        title: 'Placa'
      },
      discount: {
        title: 'Descuento'
      },
      // typerate: {
      //  title: 'Tipo de Tarifa'
      // },
      lostTicket: {
        title: 'Ticket Perdido'
      },
      totalValue: {
        title: 'Valor Recaudado',
        type: 'html',
        valuePrepareFunction: (data) => '<p class="text-right m-0">' + data + '</p>'
      }
    }
  };

  constructor(
    private parkingService: ParkingService,
    private parkingEventLogService: ParkingEventLogService,
    private utilsService: UtilsService,
    private decimalPipe: DecimalPipe
  ) {
    this.items = new LocalDataSource();
  }

  ngOnInit() {
    this.parkingService.get().subscribe(response => {
      this.parking = response;
    });
  }
  getDataByDates() {
    if (this.dateFrom && this.dateTo) {
      // this.dateFrom = '2019-02-01T00:00';
      // this.dateTo = '2019-03-01T00:00';
      this.parkingEventLogService.getByDates(this.dateFrom, this.dateTo).subscribe(response => {
        if (response['code'] === 204) {
          alert(response['message']);
        } else {
          const tempItems = this.getFormattedData(response);
          this.totalValue = 0;
          tempItems.forEach((item) => {
            this.totalValue += item.payment;
          });
          this.items = new LocalDataSource(tempItems);
        }
      });
    } else {
      alert('Seleccione las fechas');
    }
  }
  getFormattedData(data) {
    const formattedData = data.map(item => {
      item.departureDate = this.utilsService.transformDateEC(item.departureDate);
      item.entryDate = this.utilsService.transformDateEC(item.entryDate);
      item.okDepartureDate = formatDate(item.departureDate, 'dd/MM/yyyy', 'en-US');
      item.entryHour = formatDate(item.entryDate, 'HH:mm:ss', 'en-US');
      item.departureHour = formatDate(item.departureDate, 'HH:mm:ss', 'en-US');
      item.timeInParking = item.additionals && item.additionals['timeInParking'] ? item.additionals['timeInParking'] : 'N/A';
      let license = 'No Existe';
      if (item.temporaryDriver && item.temporaryDriver.licensePlate) {
        license = item.temporaryDriver.licensePlate;
      }
      if (item.driver && item.driver.licensePlate) {
        license = item.driver.licensePlate;
      }
      item.licensePlate = license;
      item.discount = item.additionals && item.additionals['discount'] ?
        item.additionals.discount.name + ' (' + item.additionals.discount.hours + ' horas)'
        : 'Normal';
      item.totalValue = this.decimalPipe.transform(item.payment, '1.2-2');
      item.lostTicket = item.additionals && item.additionals['lostTicket'] ? 'SI' : 'NO';
      return item;
    });
    formattedData.sort(function (a, b) {
      return a.departureDate - b.departureDate;
    });
    return formattedData;
  }
  getTotalValue(data) {
    let totalValue = 0;
    if (data && data.length > 0) {
      data.forEach((item) => {
        totalValue += item.payment;
      });
    }
    return totalValue;
  }
}
