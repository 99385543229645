import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ReportsModule } from './reports/reports.module';
import { DiscountsModule } from './discounts/discounts.module';
import { AuthModule } from './auth/auth.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { InvoicesModule } from './invoices/invoices.module';





@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    FormsModule,
    Ng2SmartTableModule,
    AuthModule,
    ReportsModule,
    DiscountsModule,
    InvoicesModule,
    BrowserAnimationsModule,
    GridModule,
    DateInputsModule,
    ExcelModule,
    ExcelExportModule,
    SharedModule,
    CoreModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
