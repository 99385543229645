import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingPageService {
  public showLoadingPage = new BehaviorSubject(false);

  constructor() { }

  set(value) {
    this.showLoadingPage.next(value);
  }
}
