import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { LoadingPageService } from './core/services/loading-page.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Reportes SSPOT';
  nameUser: string;
  isLoggedIn: boolean;
  showLoading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingPageService: LoadingPageService,
    private cdRef: ChangeDetectorRef
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.nameUser = sessionStorage.getItem('name');
        this.authService.getIsLoggedIn().subscribe(value => {
          this.isLoggedIn = value;
        });
      }
    });
  }

  ngOnInit() {
    this.nameUser = sessionStorage.getItem('name');
    this.loadingPageService.showLoadingPage.subscribe(resp => {
      this.showLoading = resp;
      this.cdRef.detectChanges();
    });
  }

  closeSession() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

}
