import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParkingService {
  private endpoint = `${environment.apiPath}/Parking`;
  token;
  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
  }
  get(): Observable<any[]> {
    const url = `${this.endpoint}/${this.token}`;
    return this.http.get(url).pipe(map((response: any) => response));
  }
}
