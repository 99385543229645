import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error;
  /**
  * Formulario de autenticación
  */
  loginForm: FormGroup;

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.title.setTitle('Login');
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  submit(): void {
    this.error = null;
    if (this.loginForm.valid) {

      this.auth.login(this.loginForm.value)
        .subscribe((resp: any) => {
          if (resp) {
            if (resp.parkingid) {
              if (resp.parkingid === 'error_usuario' || resp.parkingid === 'error_password') {
                this.error = 'Hubo un problema con los datos, no corresponden.';
              } else {
                if (['VIEWER'].indexOf(resp.roles) > -1) {
                  sessionStorage.setItem('name', resp.firstName + ' ' + resp.lastName);
                  sessionStorage.setItem('token', resp.parkingid);
                  sessionStorage.setItem('parking', JSON.stringify(resp.parking));
                  this.router.navigateByUrl('/home/report');
                  this.auth.setLogin();
                } else {
                  this.error = 'Usuario no autorizado.';
                }

              }
            } else {
              this.error = 'Ocurrió un error';
            }
          } else {
            this.error = 'Ocurrió un error';
          }
        },
          err => {
            switch (err.status) {
              case 401:
                this.error = 'Usuario o contraseña no son correctos.';
                break;
              default:
                this.error = 'Ha ocurrido un error inesperado.';
                break;
            }
          }
        );
    }
  }
}
