import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { ByDateReportComponent } from './by-date-report/by-date-report.component';
import { CarsInsideReportComponent } from './cars-inside-report/cars-inside-report.component';
import { ReportComponent } from './report/report.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { GeneralReportComponent } from './general-report/general-report.component';
import { BackButtonEventComponent } from './back-button-event/back-button-event.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CreditCardReportComponent } from './credit-card-report/credit-card-report.component';


@NgModule({
  imports: [
    Ng2SmartTableModule,
    CommonModule,
    SharedModule,
    CoreModule,
    RouterModule.forRoot([])
  ],
  declarations: [
    DailyReportComponent,
    ByDateReportComponent,
    CarsInsideReportComponent,
    ReportComponent,
    GeneralReportComponent,
    BackButtonEventComponent,
    CreditCardReportComponent
  ],
  providers: [DecimalPipe]
})
export class ReportsModule { }
