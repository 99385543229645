import { Component, OnInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable, empty } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { formatDate } from '@angular/common';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { InvoiceService } from 'src/app/core/services/invoice.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  public rangeDates = { dateFrom: new Date(), dateTo: new Date() };
  public dateFromString = '';
  public dateToString = '';
  public parkingId = '';
  public dataGrid: Observable<GridDataResult>;

  constructor(
    private invoiceService: InvoiceService,
    private utilService: UtilsService
  ) { }

  ngOnInit() {
    this.parkingId = sessionStorage.getItem('token');
    this.rangeDates.dateTo = new Date(this.rangeDates.dateTo.setDate(this.rangeDates.dateTo.getDate() + 1));
    this.getData();
  }
  formatData(data) {
    if (!data || data.length <= 0) {
      return [];
    }
    const newData = data.map(item => {
      if (item.date) {
        const currentDate = item.date;
        item.date = this.utilService.transformDateEC(currentDate);
        item.invoiceDate = formatDate(item.date, 'yyyy-MM-dd', 'en-US');
      }
      if (item.eInvoiceStatus && item.eInvoiceStatus[0] && item.eInvoiceStatus[0].date) {
        const approvalDate = item.eInvoiceStatus[0].date;
        item.approvalDate = this.utilService.transformDateEC(approvalDate);
        item.expirationDate = formatDate(item.approvalDate, 'yyyy-MM-dd', 'en-US');
      }
      item.identification = item.recipient ? item.recipient.idCard : '0000000000';
      item.status = 'S';
      item.subtotal = item.subtotal ? item.subtotal.toFixed(2) : 0;
      item.ivaTax = item.ivaTax ? item.ivaTax.toFixed(2) : 0;
      item.service = 0;
      item.tips = 0;
      item.total = item.total ? item.total.toFixed(2) : 0;
      item.paymentMethod = 'EFECTIVO';
      item.voided = 'FALSO';
      item.clientName = item.recipient ? item.recipient.name : 'Nombre del cliente';
      item.invoiceDetail = 'Ventas del dia';
      item.invCode = '10001';
      return item;
    });
    return newData;
  }

  setTimeToZero(date) {
    var current = date;
    current.setHours(0);
    current.setMinutes(0);
    current.setSeconds(0);
    current.setMilliseconds(0);
    return current;
  }
  setTimeToTwelve(date) {
    var current = date;
    current.setHours(23);
    current.setMinutes(59);
    current.setSeconds(59);
    current.setMilliseconds(0);
    return current;
  }
  getData() {
    this.dateFromString = this.setTimeToZero(this.rangeDates.dateFrom).toISOString();
    this.dateToString = this.setTimeToTwelve(this.rangeDates.dateTo).toISOString();
    this.parkingReport();
  }
  parkingReport() {
    const data = {
      dateFrom: this.dateFromString,
      dateTo: this.dateToString,
      parking: this.parkingId
    };
    this.invoiceService.get(data).subscribe((resp: any) => {
      if (resp['code'] === 204) {
        alert(resp['message']);
        this.dataGrid = null;
        return;
      }
      const newResp = this.formatData(resp);
      this.dataGrid = newResp;
    });
  }

}
