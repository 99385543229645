import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private endpoint = `${environment.apiPath}/ParkingOwner/login`;
  isLoggedIn = new BehaviorSubject(false);
  constructor(private http: HttpClient) {
    if (sessionStorage.getItem('token')) {
      this.isLoggedIn.next(true);
    } else {
      this.isLoggedIn.next(false);
    }
  }

  login(data): Observable<any[]> {
    const url = `${this.endpoint}`;
    const params = {
      user: data.username,
      password: data.password
    };
    return this.http.post(url, params).pipe(map((response: any) => response));
  }
  setLogin() {
    if (sessionStorage.getItem('token')) {
      this.isLoggedIn.next(true);
    } else {
      this.isLoggedIn.next(false);
    }
  }
  getIsLoggedIn() {
    return this.isLoggedIn;
  }
  logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('parking');
    this.setLogin();
  }
}
