import { Component, OnInit } from '@angular/core';
import { ParkingService } from 'src/app/core/services/parking.service';
import { OwnerEventService } from 'src/app/core/services/owner-event.service';
import { formatDate } from '@angular/common';
import { LocalDataSource } from 'ng2-smart-table';
import { UtilsService } from 'src/app/core/utils/utils.service';


@Component({
  selector: 'app-back-button-event',
  templateUrl: './back-button-event.component.html',
  styleUrls: ['./back-button-event.component.scss']
})
export class BackButtonEventComponent implements OnInit {
  dateFrom;
  dateTo;
  parking;
  showTable = true;
  items: LocalDataSource;

  tableSettings = {
    attr: {
      class: 'table table-striped mb-0'
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    noDataMessage: 'No hay registros',
    pager: {
      display: false,
    },
    columns: {
      username: {
        title: 'Usuario'
      },
      action: {
        title: 'Motivo'
      },
      cardNumber: {
        title: 'No. Tarjeta'
      },
      date: {
        title: 'Fecha'
      },
      hour: {
        title: 'Hora'
      },
      isAnEntry: {
        title: 'Entrada/Salida'
      }
    }
  };
  constructor(
    private parkingService: ParkingService,
    private ownerEventService: OwnerEventService,
    private utilsService: UtilsService
  ) {
    this.items = new LocalDataSource();
  }

  ngOnInit() {
    this.parkingService.get().subscribe(response => {
      this.parking = response;
    });
  }


  getDataByDates() {
    if (this.dateFrom && this.dateTo) {
      // this.dateFrom = '2018-08-01T00:00';
      // this.dateTo = '2019-03-31T00:00';
      this.ownerEventService.getBackButtonEvents(this.dateFrom, this.dateTo).subscribe(response => {
        if (response['code'] === 204) {
          alert(response['message']);
        } else {
          this.setFormatData(response);
        }
      });
    } else {
      alert('Seleccione las fechas');
    }
  }
  setFormatData(data) {
    this.showTable = false;
    const formatedResponse = data.map((item, index) => {
      item.username = item.idOwner && item.idOwner.username ? item.idOwner.username : 'No existe';
      item.cardNumber = item.cardNumber.split('_')[1];
      console.log('date before', item.actionDate);
      item.actionDate = this.utilsService.transformDateEC(item.actionDate);
      console.log('date after', item.actionDate);
      item.action = item.action && item.action.description ?
        item.action.description :
        item.action && item.action.action ?
          item.action.action : 'No existe descripción';
      item.date = formatDate(item.actionDate, 'dd/MM/yyyy', 'en-US');
      item.hour = formatDate(item.actionDate, 'HH:mm:ss', 'en-US');
      item.isAnEntry = item.isAnEntry ? 'Entrada' : 'Salida';
      return item;
    });
    // this.items.load(formatedResponse);
    this.items = new LocalDataSource(formatedResponse);
    this.showTable = true;
  }

}
