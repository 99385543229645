import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  routes = [
    {
      id: 1,
      path: '/home/report',
      label: 'Reportes'
    },
    {
      id: 2,
      path: '/home/discounts',
      label: 'Descuentos'
    }
  ];
  activeRoute;
  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.routes.forEach(actual => {
          if (val.url.indexOf(actual.path) > -1) {
            this.activeRoute = actual;
          }
        });
      }
    });
  }

  ngOnInit() {
    const parking = JSON.parse(sessionStorage.getItem('parking'));

    if (parking && parking.invoicing && parking.invoicing.provider === 'SANTARITA') {
      this.routes.push(
        {
          id: 3,
          path: '/home/invoices',
          label: 'Facturas'
        }
      )
    }

    this.routes.forEach(actual => {
      if (window.location.href.indexOf(actual.path) > -1) {
        this.activeRoute = actual;
      }
    });
  }

}
