import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from '../reports/report/report.component';
import { DailyReportComponent } from '../reports/daily-report/daily-report.component';
import { ByDateReportComponent } from '../reports/by-date-report/by-date-report.component';
import { CarsInsideReportComponent } from '../reports/cars-inside-report/cars-inside-report.component';
import { DiscountsComponent } from '../discounts/discounts/discounts.component';
import { LoginComponent } from '../auth/login/login.component';
import { LandingComponent } from './components/landing/landing.component';
import { GeneralReportComponent } from '../reports/general-report/general-report.component';
import { BackButtonEventComponent } from '../reports/back-button-event/back-button-event.component';
import { CreditCardReportComponent } from '../reports/credit-card-report/credit-card-report.component';
import { InvoicesComponent } from '../invoices/invoices/invoices.component';

const routes: Routes = [
  {
    path: 'home',
    component: LandingComponent,
    children: [
      {
        path: 'report',
        component: ReportComponent,
        children: [
          {
            path: 'daily',
            component: DailyReportComponent
          },
          {
            path: 'by-date',
            component: ByDateReportComponent
          },
          {
            path: 'cars-inside',
            component: CarsInsideReportComponent
          },
          {
            path: 'general',
            component: GeneralReportComponent
          },
          {
            path: 'backButton',
            component: BackButtonEventComponent
          },
          {
            path: 'creditCard',
            component: CreditCardReportComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'daily'
          }
        ]
      },
      {
        path: 'discounts',
        component: DiscountsComponent
      },
      {
        path: 'invoices',
        component: InvoicesComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
