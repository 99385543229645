import { Component, OnInit } from '@angular/core';
import { ParkingService } from 'src/app/core/services/parking.service';
import { ParkingEventLogService } from 'src/app/core/services/parking-event-log.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.scss']
})
export class GeneralReportComponent implements OnInit {

  dateFrom;
  dateTo;
  parking;
  items;
  itemsByDay = [
    {
      idDay: 0,
      nameDay: 'Domingo',
      items: []
    },
    {
      idDay: 1,
      nameDay: 'Lunes',
      items: []
    },
    {
      idDay: 2,
      nameDay: 'Martes',
      items: []
    },
    {
      idDay: 3,
      nameDay: 'Miércoles',
      items: []
    },
    {
      idDay: 4,
      nameDay: 'Jueves',
      items: []
    },
    {
      idDay: 5,
      nameDay: 'Viernes',
      items: []
    },
    {
      idDay: 6,
      nameDay: 'Sábado',
      items: []
    }
  ];

  // Variables:
  totalValue: number;
  numberOfTransactions: number;
  numberWorkedDays: number;
  monthlyValueBySpot: number;
  dailyValueBySpot: number;
  monthlyTransactionsBySpot: number;
  dailyTransactionsBySpot: number;
  dayWithMoreEntries;
  timeWithMoreEntries = {
    time: '-',
    percentage: 0
  };
  timeWithMoreDepartures = {
    time: '-',
    percentage: 0
  };
  averageIncome: number;
  totalWorkedTime: number;
  totalWorkedHours: number;
  averageParkingTime: number;
  daysWithTimes: any;
  maxWorkedHours: number;
  maxTransactions: number;
  maxIncomeBySpot: number;

  productivityBySpot: number;
  productivityByTransaction: number;
  productivityByHours: number;

  constructor(
    private parkingService: ParkingService,
    private parkingEventLogService: ParkingEventLogService
  ) { }

  ngOnInit() {
    this.parkingService.get().subscribe(response => {
      this.parking = response;
      this.getTimesByDays();
    });
  }

  transformDateEC(date) {
    const dateWithOutTime = date.split('.')[0];
    const timeDate = new Date(dateWithOutTime).getTime();
    const fiveHours = 5 * 60 * 60 * 1000;
    const dateEc = timeDate - fiveHours;
    return new Date(dateEc);
  }

  groupByFunction(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  getTimesByDays() {
    const timeDays = this.groupByFunction(this.parking.attentionDays, 'weekDay');
    const objectDaysWithTimes = {};
    // tslint:disable-next-line:forin
    for (const prop in timeDays) {
      const dayTotal = timeDays[prop].reduce((prev, item) => {
        const startHour = parseFloat(item.startHour.split(':')[0]);
        const endHour = parseFloat(item.endHour.split(':')[0]);
        const startMinutes = parseFloat(item.startHour.split(':')[1]);
        const endMinutes = parseFloat(item.endHour.split(':')[1]);
        const totalHour = endHour - startHour;
        const totalMinutes = endMinutes - startMinutes;
        const sumTime = {
          hours: prev.hours + totalHour,
          minutes: prev.minutes + totalMinutes,
          totalhours: prev.hours + totalHour + ((prev.minutes + totalMinutes) / 60)
        };
        return sumTime;
      }, { hours: 0, minutes: 0, totalhours: 0 });
      objectDaysWithTimes[prop] = dayTotal;
    }
    this.daysWithTimes = objectDaysWithTimes;
    console.log('objectDaysWithTimes', objectDaysWithTimes);
  }

  getDataByDates() {
    if (this.dateFrom && this.dateTo) {
      // this.dateFrom = '2018-11-01T00:00';
      //  this.dateTo = '2018-11-30T00:00';
      this.parkingEventLogService.getByDates(this.dateFrom, this.dateTo).subscribe(response => {
        if (response['code'] === 204) {
          alert(response['message']);
        } else {
          this.items = response.map(item => {
            item.updatedAt = item.updatedAt ? this.transformDateEC(item.updatedAt) : null;
            item.createdAt = item.createdAt ? this.transformDateEC(item.createdAt) : null;
            item.departureDate = item.departureDate ? this.transformDateEC(item.departureDate) : null;
            item.entryDate = item.entryDate ? this.transformDateEC(item.entryDate) : null;
            item.dayDeparture = item.departureDate.getDay();
            item.formattedDepartureDate = formatDate(item.departureDate, 'dd/MM/yyyy', 'en-US');
            return item;
          });
          this.numberOfTransactions = response.length;
          const workedDays = this.groupByFunction(this.items, 'formattedDepartureDate');
          this.numberWorkedDays = Object.keys(workedDays).length;
          console.log('workedDays', workedDays);
          this.getMaxHoursWorked(workedDays);
          this.getItemsByDay(this.items);
          this.getTotalPayment(this.items);
          this.getTotalTime();
          this.getTimeWithMoreActions(this.items, true);
          this.getTimeWithMoreActions(this.items, false);

          this.getMaxTransactions();
          this.getMaxIncomeBySpot();

          this.getProductivityBySpot();
          this.getProductivityByTransaction();
          this.getProductivityByHours();
        }
      });
    } else {
      alert('Seleccione las fechas');
    }

  }
  getMaxHoursWorked(workedDays) {
    let maxWorkedHours = 0;
    // tslint:disable-next-line:forin
    for (const dayName in workedDays) {
      const arrayDay = dayName.split('/');
      const formattedDay = arrayDay[2] + '/' + arrayDay[1] + '/' + arrayDay[0];
      const day = new Date(formattedDay);
      const weekDay = day.getDay();
      switch (weekDay) {
        case 0:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['DOMINGO'] ? this.daysWithTimes['DOMINGO'].totalhours : 0);
          break;
        case 1:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['LUNES'] ? this.daysWithTimes['LUNES'].totalhours : 0);
          break;
        case 2:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['MARTES'] ? this.daysWithTimes['MARTES'].totalhours : 0);
          break;
        case 3:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['MIERCOLES'] ? this.daysWithTimes['MIERCOLES'].totalhours : 0);
          break;
        case 4:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['JUEVES'] ? this.daysWithTimes['JUEVES'].totalhours : 0);
          break;
        case 5:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['VIERNES'] ? this.daysWithTimes['VIERNES'].totalhours : 0);
          break;
        case 6:
          maxWorkedHours = maxWorkedHours + (this.daysWithTimes['SABADO'] ? this.daysWithTimes['SABADO'].totalhours : 0);
          break;
      }
    }
    console.log('maxWorkedHours', maxWorkedHours);
    this.maxWorkedHours = maxWorkedHours;
  }
  getTotalPayment(items) {
    this.totalValue = 0;
    this.totalValue = this.items.reduce((prev, item) => {
      return prev + item.payment;
    }, 0);

    this.averageIncome = this.totalValue / this.numberOfTransactions;

    this.getMonthlyDailyValues();
    this.getMonthlyDailyTransactions();
  }
  getTotalTime() {
    const hoursTotal = this.items.reduce((prev, item) => {
      return prev + parseFloat(item.additionals.timeInParking.split(':')[0]);
    }, 0);
    const minutesTotal = this.items.reduce((prev, item) => {
      return prev + parseFloat(item.additionals.timeInParking.split(':')[1]);
    }, 0);
    const hoursTimestamp = hoursTotal * 60 * 60 * 1000;
    const minutesTimestamp = minutesTotal * 60 * 1000;
    this.totalWorkedTime = hoursTimestamp + minutesTimestamp;
    console.log('totalWorkedTime', hoursTimestamp + minutesTimestamp);
    this.averageParkingTime = (this.totalWorkedTime / (60 * 60 * 1000)) / this.numberOfTransactions;
    this.totalWorkedHours = this.totalWorkedTime / (60 * 60 * 1000);
  }
  getItemsByDay(items) {
    items.forEach((item) => {
      const departureDate = item.departureDate;
      this.itemsByDay[departureDate.getDay()].items.push(item);
    });
    console.log('itemsByDay', this.itemsByDay);
    this.getDayWithMoreItems();
  }
  getDayWithMoreItems() {
    this.dayWithMoreEntries = {
      day: null,
      percentage: 0
    };
    this.dayWithMoreEntries.day = this.itemsByDay.reduce((prev, itemDay) => {
      if (prev.items.length > itemDay.items.length) {
        return prev;
      } else {
        return itemDay;
      }
    }, this.itemsByDay[0]);
    this.dayWithMoreEntries.percentage = (this.dayWithMoreEntries.day.items.length * 100) / this.items.length;
  }
  getMonthlyDailyValues() {
    this.monthlyValueBySpot = 0;
    this.dailyValueBySpot = 0;
    this.monthlyValueBySpot = this.totalValue / this.parking.capacity;
    this.dailyValueBySpot = this.totalValue / (this.parking.capacity * this.numberWorkedDays);
  }
  getMonthlyDailyTransactions() {
    this.monthlyTransactionsBySpot = 0;
    this.dailyTransactionsBySpot = 0;
    this.monthlyTransactionsBySpot = this.numberOfTransactions / this.parking.capacity;
    this.dailyTransactionsBySpot = this.numberOfTransactions / (this.parking.capacity * this.numberWorkedDays);
  }
  getTimeWithMoreActions(items, isEntry = true) {
    const timeWithActions = items.reduce((prev, item) => {
      const itemDate = isEntry ? item.entryDate : item.departureDate;
      prev[itemDate.getHours()] ? prev[itemDate.getHours()].push(item) : prev[itemDate.getHours()] = [item];
      return prev;
    }, {});
    const arrayTimes = [];
    // tslint:disable-next-line:forin
    for (const prop in timeWithActions) {
      const newObjectHour = {
        hour: prop,
        length: timeWithActions[prop].length
      };
      arrayTimes.push(newObjectHour);
    }

    const timeActions = arrayTimes.reduce((prev, itemTime) => {
      if (itemTime.length > prev.length) {
        return itemTime;
      } else {
        return prev;
      }
    }, arrayTimes[0]);
    if (isEntry) {
      this.timeWithMoreEntries.time = timeActions.hour;
      this.timeWithMoreEntries.percentage = (timeActions.length * 100) / items.length;
    } else {
      this.timeWithMoreDepartures.time = timeActions.hour;
      this.timeWithMoreDepartures.percentage = (timeActions.length * 100) / items.length;
    }
  }
  getMaxTransactions() {
    this.maxTransactions = this.maxWorkedHours / this.averageParkingTime;
    console.log('maxTransactions', this.maxTransactions);
  }
  getMaxIncomeBySpot() {
    this.maxIncomeBySpot = this.averageIncome * this.maxTransactions;
    console.log('maxIncomeBySpot', this.maxIncomeBySpot);
  }
  getProductivityBySpot() {
    this.productivityBySpot = this.monthlyValueBySpot / this.maxIncomeBySpot * 100;
    console.log('productivityBySpot', this.productivityBySpot);
  }
  getProductivityByTransaction() {
    this.productivityByTransaction = this.monthlyTransactionsBySpot / this.maxTransactions * 100;
    console.log('productivityByTransaction', this.productivityByTransaction);
  }
  getProductivityByHours() {
    this.productivityByHours = this.totalWorkedHours / this.parking.capacity / this.maxWorkedHours * 100;
    console.log('productivityByHours', this.productivityByHours);
  }
}
