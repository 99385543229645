import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OwnerEventService {
  private endpoint = `${environment.apiPath}/OwnerEvent`;
  token;
  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
  }

  changeDateToISOString(date, timeZero = false) {
    let dateFormated;
    if (date instanceof Date) {
      if (timeZero) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
      }
      dateFormated = date.toISOString();
    } else {
      const arrayDate = date.split('-');
      const year = arrayDate[0];
      const month = parseInt(arrayDate[1], 0) - 1;
      let preDay = arrayDate[2];
      let arrayTime = [];
      let hours = 0;
      let minutes = 0;
      if (preDay.indexOf('T') > -1) {
        const arrayDayWithT = preDay.split('T');
        preDay = arrayDayWithT[0];
        arrayTime = arrayDayWithT[1].split(':');
        hours = arrayTime[0];
        minutes = arrayTime[1];
      }
      const day = preDay;
      dateFormated = new Date(year, month, day, hours, minutes).toISOString();
    }
    return dateFormated;
  }

  getBackButtonEvents(dateFrom, dateTo): Observable<any[]> {
    const dateFromFormated = this.changeDateToISOString(dateFrom, false);
    const dateToFormated = this.changeDateToISOString(dateTo, false);
    const url = `${this.endpoint}/getEventsByParkingAndDates`;
    const params = {
      parking: this.token,
      dateFrom: dateFromFormated,
      dateTo: dateToFormated
    };
    return this.http.get(url, { params: params }).pipe(map((response: any) => response));
  }
}
