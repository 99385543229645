import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../../core/services/parking.service';
import { ParkingEventLogService } from '../../core/services/parking-event-log.service';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {

  date;
  parking;
  items = [];
  totalValue: number;

  constructor(
    private parkingService: ParkingService,
    private parkingEventLogService: ParkingEventLogService
  ) { }

  ngOnInit() {
    this.parkingService.get().subscribe(response => {
      this.parking = response;
      this.date = new Date();
      this.getDataByDate();
    });
  }
  getDataByDate() {
    if (this.date) {
      this.parkingEventLogService.getByDate(this.date).subscribe(response => {
        if (response['code'] === 204) {
          alert(response['message']);
        } else {
          if (response && response.length > 0) {
            const newResp = response.map(item => {
              let license = 'No Existe';
              if (item.temporaryDriver && item.temporaryDriver.licensePlate) {
                license = item.temporaryDriver.licensePlate;
              }
              if (item.driver && item.driver.licensePlate) {
                license = item.driver.licensePlate;
              }
              item.licensePlate = license;
              return item;
            });
            this.items = newResp;
            this.totalValue = 0;
            this.items.forEach((item) => {
              this.totalValue += item.payment;
            });
          } else {
            alert('Su consulta no obtuvo resultados');
            this.items = [];
          }
        }

      });
    } else {
      alert('Seleccione una fecha');
    }

  }

}
