import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public transformDateEC(date) {
    const dateWithOutTime = JSON.parse(JSON.stringify(date));
    const getFullYear = new Date(dateWithOutTime).getUTCFullYear();
    const getMonth = new Date(dateWithOutTime).getUTCMonth();
    const getDate = new Date(dateWithOutTime).getUTCDate();
    const getHours = new Date(dateWithOutTime).getUTCHours();
    const getMinutes = new Date(dateWithOutTime).getUTCMinutes();
    const getSeconds = new Date(dateWithOutTime).getUTCSeconds();
    const timeDate = new Date(getFullYear, getMonth, getDate, getHours, getMinutes, getSeconds).getTime();
    const fiveHours = 5 * 60 * 60 * 1000;
    const dateEc = timeDate - fiveHours;
    return new Date(dateEc);
  }

  public transformDateToString(date) {
    const dateWithOutTime = JSON.parse(JSON.stringify(date));
    const getFullYear = new Date(dateWithOutTime).getUTCFullYear();
    const getMonth = new Date(dateWithOutTime).getUTCMonth();
    const getDate = new Date(dateWithOutTime).getUTCDate();
    const getHours = new Date(dateWithOutTime).getUTCHours();
    const getMinutes = new Date(dateWithOutTime).getUTCMinutes();
    const getSeconds = new Date(dateWithOutTime).getUTCSeconds();
    const newDate = new Date(getFullYear, getMonth, getDate, getHours, getMinutes, getSeconds);
    return newDate.toISOString();
  }
}
