import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DateInputsModule,
    ExcelModule,
    ExcelExportModule,
    RouterModule
  ],
  exports: [
    FormsModule,
    GridModule,
    DateInputsModule,
    ExcelModule,
    ExcelExportModule,
    ReactiveFormsModule
  ],
  declarations: []
})
export class SharedModule { }
