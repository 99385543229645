import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private endpoint = `${environment.apiPath}/PaymentLog`;
  constructor(
    private http: HttpClient
  ) {
  }

  userReport(data): Observable<any> {
    const url = `${this.endpoint}` + '/userReport';
    return this.http.get(url, { params: data });
  }
  parkingReport(data): Observable<any> {
    const url = `${this.endpoint}` + '/parkingReport';
    return this.http.get(url, { params: data });
  }
  consolidadoReport(data): Observable<any> {
    const url = `${this.endpoint}` + '/consolidadoReport';
    return this.http.get(url, { params: data });
  }
  consolidadoReportWithStatus(data): Observable<any> {
    const url = `${this.endpoint}` + '/conciliacionReport';
    return this.http.get(url, { params: data });
  }
}
