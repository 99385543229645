import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../../core/services/parking.service';
import { ParkingEventLogService } from '../../core/services/parking-event-log.service';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {
  parking;
  items;
  constructor(
    private parkingService: ParkingService
  ) { }

  ngOnInit() {
    this.parkingService.get().subscribe((response: any) => {
      if (response['code'] === 204) {
        alert(response['message']);
      } else {
        this.parking = response;
        this.items = response.discountByHours;
      }

    });
  }

}
