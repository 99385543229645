import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private endpoint = `${environment.apiPath}/Invoice/reportByParkingAndDates`;
  constructor(
    private http: HttpClient
  ) {
  }

  get(params): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.get(url, { params: params });
  }
}
