import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  routes = [
    {
      id: 1,
      path: 'daily',
      label: 'Diarios'
    },
    {
      id: 2,
      path: 'by-date',
      label: 'Por rango de fechas'
    },
    {
      id: 3,
      path: 'cars-inside',
      label: 'Vehículos ingresados'
    },
    {
      id: 4,
      path: 'backButton',
      label: 'Eventos Back Button'
    },
    {
      id: 5,
      path: 'creditCard',
      label: 'Tarjeta de Crédito'
    },
    {
      id: 6,
      path: 'general',
      label: 'Reporte !PREMIUM!'
    }
  ];
  activeRoute;
  route$: Observable<any[]>;
  constructor(
    private router: Router,
    private title: Title
  ) {
    this.activeRoute = this.routes[0];
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.routes.forEach(actual => {
          if (val.url.indexOf(actual.path) > -1) {
            this.activeRoute = actual;
          }
        });
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('Reportes SSPOT');
  }
  savePdf() {
    window.print();
  }

}
