import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../../core/services/parking.service';
import { ParkingEventLogService } from '../../core/services/parking-event-log.service';

@Component({
  selector: 'app-cars-inside-report',
  templateUrl: './cars-inside-report.component.html',
  styleUrls: ['./cars-inside-report.component.scss']
})
export class CarsInsideReportComponent implements OnInit {

  parking;
  items;

  constructor(
    private parkingService: ParkingService,
    private parkingEventLogService: ParkingEventLogService,
  ) { }

  ngOnInit() {
    this.getData();
    
  }

  getData(){
    this.parkingService.get().subscribe(response => {
      if (response['code'] === 204) {
        alert(response['message']);
      } else {
        this.parking = response;
        this.parkingEventLogService.getEntryCars().subscribe(carsInside => {
          this.items = carsInside;
          this.items.forEach((item) => {
            if (item.cardCode) {
              if (item.cardCode.indexOf('NONSSPOT') > -1) {
                item.card = 'No. ' + item.cardCode.split('_')[1];
              } else {
                item.card = 'SSPOT';
              }
            } else {
              item.card = 'No existe';
            }
            let license = 'No Existe';
            if (item.temporaryDriver && item.temporaryDriver.licensePlate) {
              license = item.temporaryDriver.licensePlate;
            }
            if (item.driver && item.driver.licensePlate) {
              license = item.driver.licensePlate;
            }
            item.licensePlate = license;
          });
        });
      }

    });
  }

  removeCar(car){

    const today = new Date();
    const departure = today.toISOString();
  
    this.parkingEventLogService.postRemoveCar(departure,car.id,car.valueInExit).subscribe(resp=>{
      this.getData();
    });
  }

}
