import { Component, OnInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable, empty } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { formatDate } from '@angular/common';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-credit-card-report',
  templateUrl: './credit-card-report.component.html',
  styleUrls: ['./credit-card-report.component.scss']
})
export class CreditCardReportComponent implements OnInit {
  public rangeDates = { dateFrom: new Date(), dateTo: new Date() };
  public dateFromString = '';
  public dateToString = '';
  public parkingId = '';
  public dataGrid: Observable<GridDataResult>;
  public totalAmount: Number = 0;

  constructor(
    private paymentService: PaymentService,
    private utilService: UtilsService
  ) { }

  ngOnInit() {
    this.parkingId = sessionStorage.getItem('token');
    this.rangeDates.dateFrom = this.setTime(this.rangeDates.dateFrom, true);
    this.rangeDates.dateTo = this.setTime(new Date(this.rangeDates.dateTo.setDate(this.rangeDates.dateTo.getDate() + 1)), false);
    this.dateFromString = this.rangeDates.dateFrom.toISOString();
    this.dateToString = this.rangeDates.dateTo.toISOString();
  }

  setTime(date, isZero = true) {
    let newDate = date;
    if (isZero) {
      newDate.setHours(0);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
    } else {
      newDate.setHours(23);
      newDate.setMinutes(59);
      newDate.setSeconds(59);
      newDate.setMilliseconds(999);
    }
    return newDate;
  }
  formatDates(data) {
    this.totalAmount = 0;
    const newData = data.map(item => {
      if (item.desde) { item.desde = this.utilService.transformDateEC(item.desde); }
      if (item.hasta) { item.hasta = this.utilService.transformDateEC(item.hasta); }
      item.diaEntrada = formatDate(item.desde, 'dd/MM/yyyy', 'en-US');
      item.horaEntrada = formatDate(item.desde, 'HH:mm:ss', 'en-US');
      item.diaSalida = formatDate(item.hasta, 'dd/MM/yyyy', 'en-US');
      item.horaSalida = formatDate(item.hasta, 'HH:mm:ss', 'en-US');
      this.totalAmount = this.totalAmount + item.valorPercibido;
      return item;
    });
    return newData;
  }
  getData() {
    this.dateFromString = this.setTime(this.rangeDates.dateFrom, true).toISOString();
    this.dateToString = this.setTime(this.rangeDates.dateTo, false).toISOString();
    this.parkingReport();
  }
  parkingReport() {
    const data = {
      from: this.dateFromString,
      to: this.dateToString,
      parking: this.parkingId
    };
    this.paymentService.parkingReport(data).subscribe((resp: any) => {
      this.dataGrid = this.formatDates(resp.data);
    });
  }

}
