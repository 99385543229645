import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ParkingEventLogService {
  private endpoint = `${environment.apiPath}/ParkingEventLog`;
  token;
  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
  }
  changeDateToISOString(date, timeZero = false) {
    let dateFormated;
    if (date instanceof Date) {
      if (timeZero) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
      }
      dateFormated = date.toISOString();
    } else {
      const arrayDate = date.split('-');
      const year = arrayDate[0];
      const month = parseInt(arrayDate[1], 0) - 1;
      let preDay = arrayDate[2];
      let arrayTime = [];
      let hours = 0;
      let minutes = 0;
      if (preDay.indexOf('T') > -1) {
        const arrayDayWithT = preDay.split('T');
        preDay = arrayDayWithT[0];
        arrayTime = arrayDayWithT[1].split(':');
        hours = arrayTime[0];
        minutes = arrayTime[1];
      }
      const day = preDay;
      dateFormated = new Date(year, month, day, hours, minutes).toISOString();
    }
    return dateFormated;
  }

  getByDate(date): Observable<any[]> {
    // const datePipe = new DatePipe('en-US');
    // const dateFormated = datePipe.transform(date, 'dd/MMM/yy');
    const dateFormated = this.changeDateToISOString(date, true);

    const url = `${this.endpoint}/getDepartureEventsByParking`;
    const params = {
      parking: this.token,
      date: dateFormated
    };
    return this.http.get(url, { params: params }).pipe(map((response: any) => response));
  }
  getByDates(dateFrom, dateTo): Observable<any[]> {
    /*const datePipe = new DatePipe('en-US');
    const dateFromFormated = datePipe.transform(dateFrom, 'dd/MMM/yy');
    const hourFromFormated = datePipe.transform(dateFrom, 'HH:mm');
    const dateToFormated = datePipe.transform(dateTo, 'dd/MMM/yy');
    const hourToFormated = datePipe.transform(dateTo, 'HH:mm');*/
    const dateFromFormated = this.changeDateToISOString(dateFrom);
    const dateToFormated = this.changeDateToISOString(dateTo);
    const url = `${this.endpoint}/getDepartureEventsByParkingAndDates`;
    const params = {
      parking: this.token,
      dateFrom: dateFromFormated,
      dateTo: dateToFormated
      // ,
      // hourFrom: hourFromFormated,
      // hourTo: hourToFormated
    };
    return this.http.get(url, { params: params }).pipe(map((response: any) => response));
  }
  getEntryCars(): Observable<any[]> {
    const url = `${this.endpoint}/getEntryEventsByParking`;
    const params = {
      parking: this.token
    };
    return this.http.get(url, { params: params }).pipe(map((response: any) => response));
  }

  postRemoveCar(departureDate, id, payment): Observable<any[]>{
    const url = `${this.endpoint}/checkoutEvent`;
    const params = {
      departureDate: departureDate,
      id:id,
      payment : payment
    };
    return this.http.post(url, params).pipe(map((response: any) => response));
  }
}
